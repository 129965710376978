import type { AudienceCopyType } from "./audienceOverviewTypes";

export const AudienceTabs = ["All", "Pending", "Draft", "Closed", "Live", "Reconciled"] as const;
export type AudienceTabsType = (typeof AudienceTabs)[number];

export const AudienceStatus = ["Pending", "Live", "Closed", "Cancelled", "Reconciled", "Archived", "Draft"] as const;
export type AudienceStatusType = (typeof AudienceStatus)[number];

export const AudienceUpdateStatus = ["pending", "live", "default"] as const;
export type AudienceUpdateStatusType = (typeof AudienceUpdateStatus)[number];

export const AudienceProjectHeaders = ["Name", "Project manager", "Status", "Completes", "IR", "LOI", ""] as const;
export type AudienceProjectHeadersType = (typeof AudienceProjectHeaders)[number];

export const AudienceStats = ["progress", "medianLoi", "incidenceRateStats", "conversionRate", "screenOuts", "qualityFails", "dropOffs", "quotaFails", "quotaFailedPercentage", "surveyEntries"] as const;
export type AudienceStatsType = (typeof AudienceStats)[number];

export type Markets = {
  name: string,
  isoCode: string,
  languages?: Languages[]
}

export type Languages = {
  name: string;
  isoCode: string;
}

export type StatusCounts = {
  name: AudienceTabsType
  number: number
}

export type BatchMarket = {
  country: string
  countryName: string | null
  language: string
  languageName: string | null
  locale: string
}

export type Batch = {
  batchStatus: string;
  id: string;
  displayName: string;
  lastModifiedUtc: string | null;
  status: AudienceStatusType;
  createdUtc: string;
  description: string | null;
  createdByName: string | null;
  lengthOfInterview: number;
  quantity: number;
  incidenceRate: number;
  cpi: number;
  currency: string;
  currencySymbol: string;
  market: BatchMarket | null;
  projectManagerEmail: string | null;
  gatekeeperUrl: string | null;
  gatekeeperTestUrl: string | null;
  completes: number;
  loi: { actual: number, difference: number };
  incidenceRateStats: { actual: number, difference: number };
  conversionRate: number;
  dropOffPercentage: number;
  quotaFailedPercentage: number;
  surveyEntries: number;
  qualityFails: { number: number, percentage: number };
  dropOffs: { number: number, percentage: number };
  screenOuts: { number: number, percentage: number };
  quotaFails: { number: number, percentage: number };
  etag: string | null;
}

export type Sample = {
  id: string;
  name: string;
  projectId: string;
  description: string;
  createdUtc: string;
  lastModifiedUtc: string;
  createdByName: string | null;
  sumCompletes: number;
  avgCPI: number;
  loi: { actual: number, difference: number };
  incidenceRateStats: { actual: number, difference: number };
  audiences: AudiencesType[],
  totalStatus: "draft" | "live" | "closed";
}

export type AudiencesType = {
  id: string;
  projectNumber: string | null;
  customerId: string | null;
  sampleId: string | null;
  displayName: string;
  countryCode: string;
  surveyType: "AdHoc" | "Tracker" | "Recontact";
  isOpen: boolean;
  reconciliationId: string | null;
  createdUtc: string;
  createdByEmail: string;
  sumCompletes: number;
  avgCPI: number;
  loi: { actual: number, difference: number };
  incidenceRateStats: { actual: number, difference: number };
  batches: Batch[];
  totalStatus: "draft" | "live" | "closed";
}

export type SelectedItemsNav = { projectId?: string, sampleId?: string, audienceId?: string, batchId?: string }

export type AudiencesData = {
  continuationToken?: string | null;
  totalCount: number;
  samples: Sample[];
}

export type AudiencesSuppliersData = {
  platformId: number
  name: string
  completeUrl: string
  quotaOutUrl: string
  screenOutUrl: string
  qualityFailUrl: string
}

export type AudiencesAddSuppliers = {
  name: string
  completeUrl: string
  quotaOutUrl: string
  screenOutUrl: string
  qualityFailUrl: string
}

export interface Summary {
  platform: string
  terminatedCount: string
  completedCount: string
}

export interface AudienceReconciliationItems {
  id: string,
  customerId: string,
  name: string,
  successCount: 0,
  ignoredCount: 2,
  status: string,
  supplierSummary: Summary[],
  createdUtc: string,
  createdById: string,
  createdByName: string
}

export interface AudiencesReconciliationData {
  continuationToken?: string | null
  items: AudienceReconciliationItems[]
}

export type AudienceStats = {
  current?: number;
  displayName: string;
  type: AudienceStatsType;
  number?: number;
  percentage?: number;
  total?: number;
  actual?: number;
  difference?: number;
}

export type SampleCopyType = Sample & { audienceResponse: AudienceCopyType }