import { type FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { useHistory, useParams } from 'react-router-dom'

import { setAudience } from '../../../store/reducers/audienceAccessReducer/audienceAccessReducer'
import type { RootState } from '../../../store/reducers/rootReducer'
import { AudienceSetupDetails } from './AudienceSetupDetails'
import { Icon } from '../../shared/Icon/Icon';
import { type ValidationError, handleZodValidation } from '../../shared/Zod/handleZodValidation'
import { audienceSchema } from '../../shared/Zod/ZodSchemas/audienceSchema'
import { formatFormData } from './formatFormData'
import type { AudienceOverviewType, CreateAudience } from '../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes';
import { BatchSetupDetails } from '../../BatchOverview/Details/BatchSetupDetails'
import { fetchPostJsonData, fetchPutJsonData } from '../../../services/services';

export const AudienceDetails = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { etag, audienceAction, useUniqueUrls, projectId, sampleId } = useSelector((state: RootState) => state.audienceDataReducer);
  const params: { projectId: string, sampleId: string, audienceId: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<ValidationError<typeof audienceSchema>>();
  const [isLoading, setIsLoading] = useState(false);
  const [audienceName, setAudienceName] = useState("");

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    setErrors({});
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.currentTarget));

    const formatedFormData = formatFormData(formData, etag, useUniqueUrls, audienceAction);

    handleZodValidation({
      onError: setErrors,
      data: formatedFormData,
      onSuccess: () => {
        setIsLoading(true);
        if (audienceAction !== "edit") {
          fetchPostJsonData<CreateAudience>(`au/a/projects/${projectId}/samples/${sampleId}/audiences`, token, formatedFormData, false, true)
            .then((res) => {
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Audience created successfully' } });
              setIsLoading(false)
              // Send user on successful create to batch with state passed
              history.replace({ pathname: `/projects/${projectId}/samples/${sampleId}/audience/${res.audienceId}/batch/${res.batchId}`, state: { isNewAudience: true } });
            }).catch((error) => {
              setIsLoading(false)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
            })
        }

        if (params.audienceId && params.sampleId && params.audienceId) {
          fetchPutJsonData<AudienceOverviewType>(`au/a/projects/${params.projectId}/samples/${params.sampleId}/audiences/${params.audienceId}`, token, formatedFormData, false, true)
            .then((res) => {
              setIsLoading(false)
              dispatch(setAudience({ ...res, audienceAction: "edit" }))
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Audience updated successfully' } })
            }).catch((error) => {
              setIsLoading(false)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
            })
        }
      },
      schema: audienceSchema,
    });
  }

  return (
    <form onSubmit={onFormSubmit} className='d-flex flex-column gap-lg'>
      <AudienceSetupDetails errors={errors} setAudienceName={setAudienceName} />

      {audienceAction && audienceAction !== "edit" && (
        <BatchSetupDetails audienceErrors={errors} audienceName={audienceName} />
      )}

      <footer className='p-4 bg-white'>
        <div className='d-flex justify-content-end align-items-center board-container'>
          <Tooltip anchorElement="target" position="top">
            <span title='All fields marked with an asterisk (*) must be completed, as they are mandatory.' className='btn btn-shadow p-1 d-flex align-items-center mr-2'>
              <Icon type='info-tooltip' className='pe-none' />
            </span>
          </Tooltip>
          <button type='submit' disabled={isLoading} className='btn btn-primary h-32 strong'>
            Save changes
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
            }
          </button>
        </div>
      </footer>
    </form>
  )
}