import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import type { RootState } from "../../../store/reducers/rootReducer";
import { Icon } from "../../shared/Icon/Icon";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BatchOverviewTabs, BatchStatus, type BatchStatusType, type BatchOverviewTabsType } from "../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import UpdateStatusModal from "../Modals/UpdateStatusModal";
import { editAudiencesNavigation } from "../../../store/reducers/audiencesNavigationReducer/audiencesNavigationReducer";

type Props = {
  selectedTab: BatchOverviewTabsType;
  setSelectedTab: (tab: BatchOverviewTabsType) => void;
}

export const BatchHeader = ({ selectedTab, setSelectedTab, }: Props) => {
  const { batchAction, displayName, status, audienceInfo, sampleInfo } = useSelector((state: RootState) => state.batchDataReducer);
  const navigationData = useSelector((state: RootState) => state.audiencesNavigationReducer);

  const dispatch = useDispatch();
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId, batchId } = params;

  const [showUpdateStatustModal, setShowUpdateStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(status);

  // const statusOptions = false ? ["Live", "Closed", "Pending", "Reconciled"] : ["Draft", "Live", "Closed", "Pending", "Reconciled"];

  const handleChangeStatus = (item: BatchStatusType) => {
    setSelectedStatus(item)
    setShowUpdateStatusModal(true)
  }

  return (
    <Fragment>
      {
        showUpdateStatustModal &&
        <UpdateStatusModal selectedStatus={selectedStatus} setShowUpdateStatusModal={setShowUpdateStatusModal} />
      }

      <section className="bg-white">
        <div className="d-flex gap-md justify-content-between p-6 border-bottom">
          <div className="d-flex gap-lg">
            <Tooltip anchorElement="target" position="bottom">
              <Link
                title={`Back to ${audienceInfo?.displayName}`}
                to={batchAction === "copy" ? `/projects/${navigationData.projectId}/samples/${navigationData.sampleId}/audience/${navigationData.audienceId}` : `/projects/${projectId}/samples/${sampleId}/audience/${audienceId}`}
                className='d-flex h-32 w-32 justify-content-center align-items-center border cursor-pointer rounded'>
                <Icon className='pe-none' type="arrow-left" />
              </Link>
            </Tooltip>

            {
              batchAction === "new" &&
              <h1 className='stronger m-0 text-truncate cursor-default h3' title="Create new batch">
                Create new batch
              </h1>
            }

            {
              batchAction === "copy" &&
              <h1 className='stronger m-0 text-truncate cursor-default h3' title="Create batch copy">
                Create batch copy
              </h1>
            }

            {
              batchAction === "edit" &&
              <div className="d-flex flex-column gap-sm">
                <h1 className='stronger m-0 text-truncate cursor-default h3' style={{ whiteSpace: "break-spaces" }}>
                  <Link to="/audiences" onClick={() => dispatch(editAudiencesNavigation({ projectId: projectId, sampleId: sampleId, audienceId: audienceId, batchId: batchId }))}>{sampleInfo?.displayName}</Link> / <Link to={`/projects/${projectId}/samples/${sampleId}/audience/${audienceId}`}>{audienceInfo?.displayName}</Link> / {displayName}
                </h1>
                <span className={`status-badge badge-${status?.toLowerCase()}`} style={{ width: "fit-content" }}>
                  <span className='bullet mr-1' />
                  {status}
                </span>
              </div>
            }
          </div>

          {batchAction === "edit" && (
            <div className='d-flex audience-actions'>
              <div className="dropdown">
                <button style={{ width: "12rem" }} className={"btn btn-secondary justify-content-between"} type="button" id="dropdownMenuStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {`Status: ${status}`} <Icon type="chevron-left" size="sm" style={{ transform: "rotate(-90deg)" }} />
                </button>
                <div className="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuStatus">
                  {BatchStatus?.filter(item => item !== status).map((item) => (
                    <button key={item} className="dropdown-item" type="button" onClick={() => handleChangeStatus(item)}>
                      {item}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

        </div>
        {batchAction === "edit" && (
          <ul className="nav nav-pills pl-4">
            {
              BatchOverviewTabs.map((tab) => (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <li
                  key={tab}
                  className={`nav-link ${selectedTab === tab && 'active'}`}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </li>
              ))
            }
          </ul>
        )}
      </section>
    </Fragment>
  )
}