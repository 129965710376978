import { type FormEvent, Fragment, useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { returnProjectsData } from "../../shared/helpers/project/returnProjectsData/returnProjectsData";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { BaseDropDownList } from "../../shared/Inputs/BaseDropDownList/BaseDropDownList";
import type { Sample } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { handleZodValidation, type ValidationError } from "../../shared/Zod/handleZodValidation";
import { sampleSchema } from "../../shared/Zod/ZodSchemas/sampleSchema";
import { setAudiences } from "../../../store/reducers/audiencesReducer/audiencesReducer";
import type { ProjectDetails } from "../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces";
import { fetchPostJsonData, fetchPutJson } from "../../../services/services";

// Default set project id
const audienceProjectId = import.meta.env.VITE_APP_RS_AUDIENCE_PROJECT_ID

type Props = {
  setShowCreateSampleModal: (show: boolean) => void;
  type: "create" | "edit";
  sample?: Sample;
  setIsOverlayLoading?: (show: boolean) => void;
  projectDetails?: ProjectDetails;
}

type FormDataType = {
  name: string;
  description: string;
}

export const SampleModal = ({ setShowCreateSampleModal, type, sample, setIsOverlayLoading, projectDetails }: Props) => {
  const audiencesData = useSelector((state: RootState) => state.audiencesDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const dispatch = useDispatch();

  const [defaultProject, setDefaultProject] = useState({ id: "", name: "..." });
  const [projects, setProjects] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(projectDetails ? projectDetails.details?.id : audienceProjectId);
  const [errors, setErrors] = useState<ValidationError<typeof sampleSchema>>();

  useEffect(() => {
    async function getData() {
      try {
        const projects = await returnProjectsData(token, dispatch);
        !projects.error && setProjects(projects.originalData);

        const defaultProject = !projects.error && projects.originalData.filter((item: TODO) => item.id === audienceProjectId)

        setDefaultProject({ id: defaultProject.at(0).id, name: defaultProject.at(0).name })
      } catch (e) {
      }
    }
    if (type === "create") {
      getData();
    }
  }, [token, dispatch, type]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setErrors({});
    event.preventDefault();

    const formData = Object.fromEntries(new FormData(event.currentTarget)) as unknown as FormDataType;

    handleZodValidation({
      onError: setErrors,
      data: formData,
      onSuccess: () => {
        setIsLoading(true);
        if (type === "create" && selectedProjectId) {
          fetchPostJsonData<Sample>(`au/a/projects/${selectedProjectId}/samples`, token, formData, false, true)
            .then((res) => {
              setIsOverlayLoading?.(true);
              const updatedData = { ...audiencesData, samples: [{ ...res }, ...audiencesData.samples] };
              dispatch(setAudiences(updatedData))
              setShowCreateSampleModal(false);
              setIsOverlayLoading?.(false);
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Sample created" } });
            }).catch((error) => {
              setIsLoading(false);
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "No project selected" } });
          setIsLoading(false);
        }

        if (type === "edit") {
          fetchPutJson(`au/a/projects/${sample?.projectId}/samples/${sample?.id}`, token, formData, false, true)
            .then((res: TODO) => {
              if (res && !res.error && !res.message) {
                const updatedItems = audiencesData.samples.map(sample => sample.id === res.id ? { ...sample, name: res.name, description: res.description } : sample)
                dispatch(setAudiences({ ...audiencesData, samples: updatedItems }))
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Sample updated' } })
                setShowCreateSampleModal(false);
              } else {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
              }
            })
        }
      },
      schema: sampleSchema,
    });
  }

  return (
    <Dialog width={500} height={400} title={type === "create" ? "Create sample" : "Edit sample"} onClose={() => setShowCreateSampleModal(false)}>
      <form className="d-flex flex-column justify-content-between h-100 gap-md px-2 py-4" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column gap-md">

          <div className='d-flex flex-column gap-sm'>
            <label htmlFor='sampleName' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
              Name
            </label>
            <input id="sampleName" name='name' className='form-control' defaultValue={sample?.name} />

            {
              errors?.name &&
              <div className="text-danger">
                <small>{errors?.name}</small>
              </div>
            }
          </div>

          <div className='d-flex flex-column gap-sm'>
            <label htmlFor='sampleDescription' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
              Description
            </label>
            <input id="sampleDescription" name='description' className='form-control' defaultValue={sample?.description} />
          </div>

          {type === "create" &&
            <Fragment>
              {projectDetails ?
                <div className='d-flex flex-column gap-sm'>
                  <label htmlFor='project' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
                    Project
                  </label>
                  <input id="project" className='form-control' readOnly defaultValue={projectDetails.details?.name} />
                </div>
                :
                <div className="d-flex flex-column gap-sm w-100">
                  <p className="m-0">Projects</p>
                  <BaseDropDownList
                    data={projects}
                    textField="name"
                    dataItemKey="id"
                    defaultItem={defaultProject}
                    className="w-100"
                    validityStyles={false}
                    onChange={(e: TODO) => setSelectedProjectId(e.value.id)}
                  />
                </div>
              }
            </Fragment>
          }
        </div>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={() => setShowCreateSampleModal(false)}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}>
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            {type === "create" ? "Create" : "Update"}
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}