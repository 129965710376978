import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchGetJsonData } from "../../../services/services";

type Props = {
  isTestUrl: boolean
}

type UsedUniqueUrlsLinks = {
  percentageUsed: number;
  total: number;
  used: number;
}

export const UsedUniqueUrlsLinks = ({ isTestUrl }: Props) => {
  const params: { projectId: string, sampleId: string, audienceId: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [usedLinks, setUsedLinks] = useState<UsedUniqueUrlsLinks>({ percentageUsed: 0, total: 0, used: 0 });

  useEffect(() => {
    fetchGetJsonData<UsedUniqueUrlsLinks>(`au/a/projects/${params.projectId}/samples/${params.sampleId}/audiences/${params.audienceId}/uniqueurlstatus?test=${isTestUrl}`, token, false, true)
      .then((res) => {
        setUsedLinks(res)
      }).catch(() => {
        setUsedLinks({ percentageUsed: 0, total: 0, used: 0 })
      })

  }, [token, isTestUrl, params]);

  const progressBarColour = () => {
    if (usedLinks.percentageUsed < 60) return "progress-60";

    if (usedLinks.percentageUsed >= 60 && usedLinks.percentageUsed < 80) return "progress-80";

    if (usedLinks.percentageUsed >= 80) return "progress-100"
  }

  return (
    <div className="d-flex flex-column w-100 h-52 gap-sm">
      <div className="d-flex justify-content-between">
        <p className="m-0">Used links so far:</p>

        <p className='text-muted m-0'>
          {Number(usedLinks.percentageUsed)?.toFixed()}% ({usedLinks.used}/{usedLinks.total})
        </p>
      </div>

      <div className="progress w-100 mt-2" style={{ height: "6px" }} title={Number(usedLinks.percentageUsed)?.toFixed()}>
        <div
          className={`progress-bar ${progressBarColour()}`}
          role="progressbar"
          aria-label="Unique urls used progress"
          style={{ width: `${usedLinks.percentageUsed}%` }}
          aria-valuenow={usedLinks.percentageUsed}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  )
}