import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";

import { Icon } from "../../shared/Icon/Icon";
import type { AuditLogType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes";
import { AuditLogTable } from "./AuditLogTable";
import { AuditLogSkeleton } from "./AuditLogSkeleton";
import { fetchGetJsonData } from '../../../services/services';

type Props = {
  type: "batch" | "audience"
}

export const AudienceAuditLog = ({ type }: Props) => {
  const audienceData = useSelector((state: RootState) => state.audienceDataReducer);
  const batchData = useSelector((state: RootState) => state.batchDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [auditLogData, setAuditLogData] = useState<AuditLogType[]>([]);
  const [filteredAuditLogData, setFilteredAuditLogData] = useState<AuditLogType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [expandAllRows, setExpandAllRows] = useState(false);

  const auditLogUrl = type === "audience" ? `au/a/audit-logs/audiences/${audienceData.id}` : `au/a/audit-logs/batches/${batchData.id}`

  useEffect(() => {
    fetchGetJsonData<AuditLogType[]>(auditLogUrl, token, false, true)
      .then((res) => {
        setAuditLogData(res);
        setFilteredAuditLogData(res);
        setIsLoading(false);
      }).catch(() => {
        setIsError(true);
        setIsLoading(false);
      })
  }, [token, auditLogUrl]);


  const filterSearch = (searchValue: string) => {
    setSearchValue(searchValue)

    if (searchValue.length === 0) {
      setFilteredAuditLogData(auditLogData);
      setSearchValue("");
      return
    }

    if (searchValue.length <= 2) return;

    const filteredAuditLogData = auditLogData?.filter(item => {
      const userNameIncludes = item.name.toLowerCase().includes(searchValue.toLowerCase());
      const itemActionIncludes = item.item.action.toLowerCase().includes(searchValue.toLowerCase());
      return userNameIncludes || itemActionIncludes
    });

    setFilteredAuditLogData(filteredAuditLogData);
  }

  const clearSearch = () => {
    setSearchValue("");
    setFilteredAuditLogData(auditLogData);
  }

  return (
    <section className='nav-board bg-white d-flex flex-column w-100 p-5 gap-lg'>
      <div className="d-flex justify-content-between align-items-center">
        <p className="stronger m-0" style={{ fontSize: "1rem" }}>
          Audit log
        </p>

        <div className="d-flex gap-md">
          <div className="position-relative">
            <input
              name='Search'
              type="text"
              className="form-control search-input bg-white overview-input"
              placeholder="Search"
              value={searchValue}
              autoComplete="off"
              onChange={(e) => { filterSearch(e.target.value) }}
            />

            {searchValue.length >= 2 && (
              <button
                type="button"
                className="btn position-absolute search-clear-button-projects"
                style={{ right: "0", top: "0", zIndex: 11 }}
                onClick={clearSearch}
                title="Clear search"
              >
                <Icon type="close" />
              </button>
            )}
          </div>

          <button type="button" className="btn btn-secondary" onClick={() => setExpandAllRows(!expandAllRows)} style={{ width: "7rem" }}>
            {expandAllRows ? "Collapse rows" : "Expand rows"}
          </button>
        </div>
      </div>

      {isLoading ?
        <AuditLogSkeleton />
        :
        <div className='board-content-container' >
          {isError ?
            <div className="d-flex flex-column align-items-center justify-content-center gap-md my-3" style={{ height: "65svh" }}>
              <i className="fal fa-folder-open fa-2x text-muted" />
              <p className="text-muted m-0">
                No audit logs found
              </p>
            </div>
            :
            <AuditLogTable expandAllRows={expandAllRows} filteredAuditLogData={filteredAuditLogData} />
          }
        </div>
      }
    </section>
  )
}
