import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import type { RootState } from '../../../store/reducers/rootReducer'
import { fetchPutResOrJson as editStatusPutRequest } from '../../../services/services'
import { editBatchEtag, editBatchStatus } from '../../../store/reducers/batchReducer/batchReducer'

interface Props {
  setShowUpdateStatusModal: (show: boolean) => void,
  selectedStatus: "Pending" | "Draft" | "Closed" | "Live" | "Reconciled" | "Cancelled" | "Archived",
}

export default function UpdateStatusModal({ setShowUpdateStatusModal, selectedStatus }: Props) {
  const dispatch = useDispatch();
  const { etag } = useSelector((state: RootState) => state.batchDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId, batchId } = params;

  const handleUpdateStatus = () => {
    editStatusPutRequest(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/status`, token, { status: selectedStatus, etag: etag }, false, true)
      .then(async (res: TODO) => {
        const { etag } = await res.json();
        dispatch(editBatchEtag(etag));
        if (res.ok) {
          dispatch(editBatchStatus(selectedStatus))
          setShowUpdateStatusModal(false);
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Status updated successfully' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? res.title : res.message } });
        }
      })
  }

  return (
    <Dialog title={"Update Status"} onClose={() => setShowUpdateStatusModal(false)}>
      <p style={{ margin: "8px" }}>
        Please note that modifying the status may render certain inputs uneditable. <br /> Do you want to proceed?
      </p>
      <DialogActionsBar>
        <button
          type='button'
          className="btn btn-secondary"
          onClick={() => setShowUpdateStatusModal(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className="btn btn-primary"
          onClick={handleUpdateStatus}
        >
          Continue
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}