import { Fragment, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FullHeightSpinner } from "../shared/FullHeightSpinner/FullHeightSpinner"
import { ErrorMessage } from "../shared/ErrorMessage/ErrorMessage";
import { BatchHeader } from "./BatchHeader/BatchHeader";
import type { RootState } from "../../store/reducers/rootReducer";
import { setBatch } from "../../store/reducers/batchReducer/batchReducer";
import type { BatchOverviewType, BatchOverviewTabsType } from "../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import { BatchDetails } from "./Details/BatchDetails";
import { BatchTargeting } from "./Targeting/BatchTargeting";
import { AudienceAuditLog } from "../AudienceOverview/AuditLog/AudienceAuditLog";
import { Suppliers } from "./Suppliers/Suppliers";
import { fetchGetJsonData } from "../../services/services";

export const BatchOverview = () => {
  document.title = "Batch – Walr";
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId, batchId } = params;
  const dispatch = useDispatch();
  const location = useLocation<{ isNewAudience?: boolean }>();

  const [didMount, setDidMount] = useState(false);
  const [selectedTab, setSelectedTab] = useState<BatchOverviewTabsType>("Details")
  const [isLoading, setIsLoading] = useState(true);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      if (projectId && sampleId && audienceId) {
        fetchGetJsonData<BatchOverviewType>(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}`, token, false, true)
          .then((res) => {
            setIsLoading(false)
            if (location?.state?.isNewAudience) {
              setSelectedTab("Targeting");
              window.history.replaceState({}, '');
            }
            dispatch(setBatch({ ...res, batchAction: "edit" }));
          }).catch((err) => {
            setErrorModal({ show: true, message: err?.message });
            setIsLoading(false);
          })
      } else {
        setIsLoading(false);
      }
    }
  }, [projectId, sampleId, audienceId, batchId, didMount, dispatch, token, location?.state?.isNewAudience]);

  const SelectedTabComponents = () => {
    switch (selectedTab) {
      case "Details":
        return <BatchDetails />;
      case "Targeting":
        return <BatchTargeting />
      case "Audit log":
        return <AudienceAuditLog type="batch" />
      case "Suppliers":
        return <Suppliers />
      default: null;
    }
  }

  return (
    <section className='main batch-container overview h-100'>
      {isLoading ?
        <FullHeightSpinner /> :
        <Fragment>
          <BatchHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <section className='overview-container d-flex flex-column p-6 gap-lg'>
            <SelectedTabComponents />
          </section>
        </Fragment>
      }

      {
        errorModal.show &&
        <ErrorMessage
          type="modal"
          errorMessage={errorModal.message}
          onHide={() => setErrorModal({ show: false, message: '' })}
        />
      }
    </section>
  )
}